import styles from './sidenav.module.css'
import {TbArrowsExchange, TbBell, TbHome, TbKey} from 'react-icons/tb'
import { SidenavElement } from './sidenav-element.component'
import { UserInfoRedesignComponent } from '../user-info/user-info-redesign.component'
import { useAppSelector } from '../../shared/hooks/redux.hook'
import { Badge, Grid } from '@nextui-org/react'
import { JobStatusEnum } from '../../shared/models/quantdle-job-status-update'
import { useAuthenticator } from '@aws-amplify/ui-react'



export const SidenavComponent = () => {

  // const authState  = useAppSelector((state) => state.auth)
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);

  const isLogged = authStatus === 'authenticated'

  const notifications = useAppSelector(state => state.notification)
  const jobs = useAppSelector(state => state.jobStatus)

  const unreadNotifications = notifications.notifications.filter((ntc) => !ntc.isRead)
  const newJobs = jobs.filter((job) => job.status !== JobStatusEnum.COMPLETED)

  return ( isLogged ? (
    <Grid xs={0} sm={1}>
      <div className={styles.sidenav}>
        <div className={styles.sidenavHeader} style={{height:"25%"}}>
          {/* <SidenavElement icon={<TbMenu2 color='var(--nextui-colors-primary)' style={{width: '30px', height: '30px'}}/>} link='/' /> */}
        </div>
        {/* <SidenavElement icon={<AiOutlineHome color='var(--nextui-colors-primary)' style={{width: '30px', height: '30px'}}/>} link='/' /> */}
        <div style={{height:"50%"}}>
        <SidenavElement title='Home' icon={<TbHome color='var(--nextui-colors-primary)' style={{width: '30px', height: '30px', margin:"5px 0"}}/>} link='/' />
          
          {/* <SidenavElement icon={<TbChartCandle color='var(--nextui-colors-primary)' style={{width: '30px', height: '30px', margin:"5px 0"}}/>} link='/' /> */}
          <SidenavElement title='Api Keys' icon={
                <TbKey color='var(--nextui-colors-primary)' style={{width: '30px', height: '30px', margin:"5px 0"}}/>
            } 
            link='/api-key'
          />
          
          <SidenavElement title='Created Custom downloads' icon={
            <Badge style={{userSelect: 'none'}} size={'sm'} isInvisible={newJobs.length === 0} placement={'top-right'} verticalOffset="20%" horizontalOffset="20%" color={"error"} content={newJobs.length} disableOutline>
              <TbArrowsExchange color='var(--nextui-colors-primary)' style={{width: '30px', height: '30px', margin:"5px 0"}}/>
            </Badge>
            } link='/transfers'
            />
          <SidenavElement title='Notifications' icon={
              <Badge style={{userSelect: 'none'}} size={'sm'} isInvisible={unreadNotifications.length === 0} placement={'top-right'} verticalOffset="20%" horizontalOffset="20%" color={"error"} content={unreadNotifications.length} disableOutline>
                <TbBell color='var(--nextui-colors-primary)' style={{width: '30px', height: '30px', margin:"5px 0"}}/>
              </Badge>
            } link='/notifications' />
          
        </div>

        <div className={styles.sidenavHeader} style={{height:"25%", display:"flex", justifyContent:"center", alignItems:"flex-end"}}>
          <UserInfoRedesignComponent/>
        </div>
    </div>
    </Grid>

  ) : <></>)
}
