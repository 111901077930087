import { useEffect, useState } from "react"
import { useQuantdleApi } from "../shared/hooks/quantdle-api.hook"
import { Grid, Input, Spacer, Tooltip, Button } from "@nextui-org/react"
import { BsCopy } from "react-icons/bs"
import toast from 'react-hot-toast';
import { PlanModalComponent } from "../components/plan-modal/plan-modal.component";

export interface ApiKey {
    apiKey: string
    apiKeyId: string
}


export const ApiKeyPage = () => {

    const { downloadsApi } = useQuantdleApi()

    const [apiKey, setApiKey] = useState<ApiKey | undefined>(undefined)
    const [showPlanModal, setShowPlanModal] = useState<boolean>(false)

    const handleOnApiKeyIdCopy = () => {
        navigator.clipboard.writeText(apiKey.apiKeyId)
        toast.success("Api ID Copied!")
    }
    
    const handleOnApiKeyCopy = () => {
        navigator.clipboard.writeText(apiKey.apiKey)
        toast.success("Api Key Copied!")
    }
    
    const handleCreateApiKey = () => {
        if (!downloadsApi) return
        downloadsApi.apiKeyCreateGet().then((res) => {
            setApiKey({
                apiKey: res.data.value,
                apiKeyId: res.data.id
            })
        }).catch((err) => {
            // if error status is 403, then the user must upgrade their plan
            console.log(err)
            if(err.response.status === 403) {
                toast.error("Uuups! This feature seems out of your plan!")
                setShowPlanModal(true)
            }
            
        })
    }

    useEffect(() => {
            
            if (!downloadsApi) return
    
            downloadsApi.apiKeyGetGet().then((res) => {
                setApiKey({
                    apiKey: res.data.value,
                    apiKeyId: res.data.id
                })
            }).catch((err) => {
                console.log(err)
            })
    
        }, [downloadsApi])


    if(apiKey) {
        return (
            <Grid xs={12} style={{display: 'flex', flexDirection: 'column', verticalAlign: 'center', height: '100vh', justifyContent: 'center', alignItems: 'center'}}>
                <Grid style={{minWidth: '400px', display:'flex', justifyContent: 'center', flexDirection: 'column'}}>
                    <div style={{display:'flex', justifyContent: 'space-between',  alignItems: 'center'}}>
                        <Input style={{minWidth: '339px'}} labelPlaceholder="Api Key ID" initialValue={apiKey.apiKeyId}/>
                        <div style={{cursor: 'pointer'}} onClick={handleOnApiKeyIdCopy}>
                            <Tooltip trigger='hover' content={"Copy Api key ID"} placement='top'>
                                <BsCopy />
                            </Tooltip>
                        </div>
                    </div>
                    <Spacer y={2} />
                    <div style={{display:'flex', justifyContent: 'space-between',  alignItems: 'center'}}>
                        <Input.Password style={{minWidth: '300px', maxWidth: '300px'}} labelPlaceholder="Api Key" initialValue={apiKey.apiKey}/>
                        <div style={{cursor: 'pointer'}} onClick={handleOnApiKeyCopy}>
                            <Tooltip trigger='hover' content={"Copy Api key ID"} placement='top'>
                                <BsCopy />
                            </Tooltip>
                        </div>
                    </div>
                </Grid>
            </Grid>
        )
    }


    return (
        <Grid xs={12} style={{display: 'flex', flexDirection: 'column', verticalAlign: 'center', height: '100vh', justifyContent: 'center', alignItems: 'center'}}>
            <Button onClick={handleCreateApiKey}>Create API Key</Button>
            <PlanModalComponent visible={showPlanModal} onClose={() => setShowPlanModal(false)} />    
        </Grid>
    )

}

