/* tslint:disable */
/* eslint-disable */
/**
 * API Gateway for data downloads
 * API Gateway for managing downloads from user side. It also provides the means to monitor the bandwith based upon user consumption
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { CreateDownloadRequest } from '../models';
import { CreateDownloadResponse } from '../models';
import { DownloadResponse } from '../models';
import { InlineResponse200 } from '../models';
import { ListDownloadsRespose } from '../models';
import { PredefinedDownloadsResponse } from '../models';
/**
 * DownloadsApi - axios parameter creator
 * @export
 */
export const DownloadsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This endpoint allows the user to create an API key
         * @summary Create an API key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKeyCreateGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api_key/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint allows the user to get an API key
         * @summary Get an API key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKeyGetGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api_key/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint allows the user to create custom downloads based on dates and formats
         * @summary Create a download with custom configuration
         * @param {CreateDownloadRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPost: async (body: CreateDownloadRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createPost.');
            }
            const localVarPath = `/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint allows the user to delete a download
         * @summary Delete a download
         * @param {string} jobId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJobIdDelete: async (jobId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'jobId' is not null or undefined
            if (jobId === null || jobId === undefined) {
                throw new RequiredError('jobId','Required parameter jobId was null or undefined when calling deleteJobIdDelete.');
            }
            const localVarPath = `/delete/{jobId}`
                .replace(`{${"jobId"}}`, encodeURIComponent(String(jobId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint returns the download link of the file, client must be fast as the link expires in 1 minute
         * @summary get the download link of the file
         * @param {string} jobId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadGet: async (jobId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'jobId' is not null or undefined
            if (jobId === null || jobId === undefined) {
                throw new RequiredError('jobId','Required parameter jobId was null or undefined when calling downloadGet.');
            }
            const localVarPath = `/download`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (jobId !== undefined) {
                localVarQueryParameter['jobId'] = jobId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint allows the user to list all downloads
         * @summary List downloads
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint returns the download link of the file, client must be fast as the link expires in 1 minute
         * @summary get the download link of the file
         * @param {string} assetClass 
         * @param {string} aggregation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        predefinedDownloadsApiGetGet: async (assetClass: string, aggregation: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetClass' is not null or undefined
            if (assetClass === null || assetClass === undefined) {
                throw new RequiredError('assetClass','Required parameter assetClass was null or undefined when calling predefinedDownloadsApiGetGet.');
            }
            // verify required parameter 'aggregation' is not null or undefined
            if (aggregation === null || aggregation === undefined) {
                throw new RequiredError('aggregation','Required parameter aggregation was null or undefined when calling predefinedDownloadsApiGetGet.');
            }
            const localVarPath = `/predefinedDownloads/api/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuthorizer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Unused")
                    : await configuration.apiKey;
                localVarHeaderParameter["Unused"] = localVarApiKeyValue;
            }

            if (assetClass !== undefined) {
                localVarQueryParameter['assetClass'] = assetClass;
            }

            if (aggregation !== undefined) {
                localVarQueryParameter['aggregation'] = aggregation;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return the predefined downloads by the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        predefinedDownloadsApiListGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/predefinedDownloads/api/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuthorizer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Unused")
                    : await configuration.apiKey;
                localVarHeaderParameter["Unused"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint returns the download link of the file, client must be fast as the link expires in 1 minute
         * @summary get the download link of the file
         * @param {string} assetClass 
         * @param {string} aggregation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        predefinedDownloadsGetGet: async (assetClass: string, aggregation: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetClass' is not null or undefined
            if (assetClass === null || assetClass === undefined) {
                throw new RequiredError('assetClass','Required parameter assetClass was null or undefined when calling predefinedDownloadsGetGet.');
            }
            // verify required parameter 'aggregation' is not null or undefined
            if (aggregation === null || aggregation === undefined) {
                throw new RequiredError('aggregation','Required parameter aggregation was null or undefined when calling predefinedDownloadsGetGet.');
            }
            const localVarPath = `/predefinedDownloads/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (assetClass !== undefined) {
                localVarQueryParameter['assetClass'] = assetClass;
            }

            if (aggregation !== undefined) {
                localVarQueryParameter['aggregation'] = aggregation;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return the predefined downloads by the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        predefinedDownloadsListGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/predefinedDownloads/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DownloadsApi - functional programming interface
 * @export
 */
export const DownloadsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * This endpoint allows the user to create an API key
         * @summary Create an API key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKeyCreateGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<InlineResponse200>>> {
            const localVarAxiosArgs = await DownloadsApiAxiosParamCreator(configuration).apiKeyCreateGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * This endpoint allows the user to get an API key
         * @summary Get an API key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKeyGetGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<InlineResponse200>>> {
            const localVarAxiosArgs = await DownloadsApiAxiosParamCreator(configuration).apiKeyGetGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * This endpoint allows the user to create custom downloads based on dates and formats
         * @summary Create a download with custom configuration
         * @param {CreateDownloadRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPost(body: CreateDownloadRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<CreateDownloadResponse>>> {
            const localVarAxiosArgs = await DownloadsApiAxiosParamCreator(configuration).createPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * This endpoint allows the user to delete a download
         * @summary Delete a download
         * @param {string} jobId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteJobIdDelete(jobId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await DownloadsApiAxiosParamCreator(configuration).deleteJobIdDelete(jobId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * This endpoint returns the download link of the file, client must be fast as the link expires in 1 minute
         * @summary get the download link of the file
         * @param {string} jobId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadGet(jobId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<DownloadResponse>>> {
            const localVarAxiosArgs = await DownloadsApiAxiosParamCreator(configuration).downloadGet(jobId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * This endpoint allows the user to list all downloads
         * @summary List downloads
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<ListDownloadsRespose>>> {
            const localVarAxiosArgs = await DownloadsApiAxiosParamCreator(configuration).listGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * This endpoint returns the download link of the file, client must be fast as the link expires in 1 minute
         * @summary get the download link of the file
         * @param {string} assetClass 
         * @param {string} aggregation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async predefinedDownloadsApiGetGet(assetClass: string, aggregation: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<DownloadResponse>>> {
            const localVarAxiosArgs = await DownloadsApiAxiosParamCreator(configuration).predefinedDownloadsApiGetGet(assetClass, aggregation, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Return the predefined downloads by the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async predefinedDownloadsApiListGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<PredefinedDownloadsResponse>>> {
            const localVarAxiosArgs = await DownloadsApiAxiosParamCreator(configuration).predefinedDownloadsApiListGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * This endpoint returns the download link of the file, client must be fast as the link expires in 1 minute
         * @summary get the download link of the file
         * @param {string} assetClass 
         * @param {string} aggregation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async predefinedDownloadsGetGet(assetClass: string, aggregation: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<DownloadResponse>>> {
            const localVarAxiosArgs = await DownloadsApiAxiosParamCreator(configuration).predefinedDownloadsGetGet(assetClass, aggregation, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Return the predefined downloads by the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async predefinedDownloadsListGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<PredefinedDownloadsResponse>>> {
            const localVarAxiosArgs = await DownloadsApiAxiosParamCreator(configuration).predefinedDownloadsListGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DownloadsApi - factory interface
 * @export
 */
export const DownloadsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * This endpoint allows the user to create an API key
         * @summary Create an API key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKeyCreateGet(options?: AxiosRequestConfig): Promise<AxiosResponse<InlineResponse200>> {
            return DownloadsApiFp(configuration).apiKeyCreateGet(options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint allows the user to get an API key
         * @summary Get an API key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKeyGetGet(options?: AxiosRequestConfig): Promise<AxiosResponse<InlineResponse200>> {
            return DownloadsApiFp(configuration).apiKeyGetGet(options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint allows the user to create custom downloads based on dates and formats
         * @summary Create a download with custom configuration
         * @param {CreateDownloadRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPost(body: CreateDownloadRequest, options?: AxiosRequestConfig): Promise<AxiosResponse<CreateDownloadResponse>> {
            return DownloadsApiFp(configuration).createPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint allows the user to delete a download
         * @summary Delete a download
         * @param {string} jobId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteJobIdDelete(jobId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return DownloadsApiFp(configuration).deleteJobIdDelete(jobId, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint returns the download link of the file, client must be fast as the link expires in 1 minute
         * @summary get the download link of the file
         * @param {string} jobId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadGet(jobId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<DownloadResponse>> {
            return DownloadsApiFp(configuration).downloadGet(jobId, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint allows the user to list all downloads
         * @summary List downloads
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listGet(options?: AxiosRequestConfig): Promise<AxiosResponse<ListDownloadsRespose>> {
            return DownloadsApiFp(configuration).listGet(options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint returns the download link of the file, client must be fast as the link expires in 1 minute
         * @summary get the download link of the file
         * @param {string} assetClass 
         * @param {string} aggregation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async predefinedDownloadsApiGetGet(assetClass: string, aggregation: string, options?: AxiosRequestConfig): Promise<AxiosResponse<DownloadResponse>> {
            return DownloadsApiFp(configuration).predefinedDownloadsApiGetGet(assetClass, aggregation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return the predefined downloads by the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async predefinedDownloadsApiListGet(options?: AxiosRequestConfig): Promise<AxiosResponse<PredefinedDownloadsResponse>> {
            return DownloadsApiFp(configuration).predefinedDownloadsApiListGet(options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint returns the download link of the file, client must be fast as the link expires in 1 minute
         * @summary get the download link of the file
         * @param {string} assetClass 
         * @param {string} aggregation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async predefinedDownloadsGetGet(assetClass: string, aggregation: string, options?: AxiosRequestConfig): Promise<AxiosResponse<DownloadResponse>> {
            return DownloadsApiFp(configuration).predefinedDownloadsGetGet(assetClass, aggregation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return the predefined downloads by the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async predefinedDownloadsListGet(options?: AxiosRequestConfig): Promise<AxiosResponse<PredefinedDownloadsResponse>> {
            return DownloadsApiFp(configuration).predefinedDownloadsListGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DownloadsApi - object-oriented interface
 * @export
 * @class DownloadsApi
 * @extends {BaseAPI}
 */
export class DownloadsApi extends BaseAPI {
    /**
     * This endpoint allows the user to create an API key
     * @summary Create an API key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DownloadsApi
     */
    public async apiKeyCreateGet(options?: AxiosRequestConfig) : Promise<AxiosResponse<InlineResponse200>> {
        return DownloadsApiFp(this.configuration).apiKeyCreateGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * This endpoint allows the user to get an API key
     * @summary Get an API key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DownloadsApi
     */
    public async apiKeyGetGet(options?: AxiosRequestConfig) : Promise<AxiosResponse<InlineResponse200>> {
        return DownloadsApiFp(this.configuration).apiKeyGetGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * This endpoint allows the user to create custom downloads based on dates and formats
     * @summary Create a download with custom configuration
     * @param {CreateDownloadRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DownloadsApi
     */
    public async createPost(body: CreateDownloadRequest, options?: AxiosRequestConfig) : Promise<AxiosResponse<CreateDownloadResponse>> {
        return DownloadsApiFp(this.configuration).createPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * This endpoint allows the user to delete a download
     * @summary Delete a download
     * @param {string} jobId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DownloadsApi
     */
    public async deleteJobIdDelete(jobId: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return DownloadsApiFp(this.configuration).deleteJobIdDelete(jobId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * This endpoint returns the download link of the file, client must be fast as the link expires in 1 minute
     * @summary get the download link of the file
     * @param {string} jobId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DownloadsApi
     */
    public async downloadGet(jobId: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<DownloadResponse>> {
        return DownloadsApiFp(this.configuration).downloadGet(jobId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * This endpoint allows the user to list all downloads
     * @summary List downloads
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DownloadsApi
     */
    public async listGet(options?: AxiosRequestConfig) : Promise<AxiosResponse<ListDownloadsRespose>> {
        return DownloadsApiFp(this.configuration).listGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * This endpoint returns the download link of the file, client must be fast as the link expires in 1 minute
     * @summary get the download link of the file
     * @param {string} assetClass 
     * @param {string} aggregation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DownloadsApi
     */
    public async predefinedDownloadsApiGetGet(assetClass: string, aggregation: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<DownloadResponse>> {
        return DownloadsApiFp(this.configuration).predefinedDownloadsApiGetGet(assetClass, aggregation, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Return the predefined downloads by the system
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DownloadsApi
     */
    public async predefinedDownloadsApiListGet(options?: AxiosRequestConfig) : Promise<AxiosResponse<PredefinedDownloadsResponse>> {
        return DownloadsApiFp(this.configuration).predefinedDownloadsApiListGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * This endpoint returns the download link of the file, client must be fast as the link expires in 1 minute
     * @summary get the download link of the file
     * @param {string} assetClass 
     * @param {string} aggregation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DownloadsApi
     */
    public async predefinedDownloadsGetGet(assetClass: string, aggregation: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<DownloadResponse>> {
        return DownloadsApiFp(this.configuration).predefinedDownloadsGetGet(assetClass, aggregation, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Return the predefined downloads by the system
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DownloadsApi
     */
    public async predefinedDownloadsListGet(options?: AxiosRequestConfig) : Promise<AxiosResponse<PredefinedDownloadsResponse>> {
        return DownloadsApiFp(this.configuration).predefinedDownloadsListGet(options).then((request) => request(this.axios, this.basePath));
    }
}
