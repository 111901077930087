/* tslint:disable */
/* eslint-disable */
/**
 * API Gateway for data downloads
 * API Gateway for managing downloads from user side. It also provides the means to monitor the bandwith based upon user consumption
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

 /**
 * 
 *
 * @export
 * @interface CreateDownloadRequest
 */
export interface CreateDownloadRequest {

    /**
     * @type {Date}
     * @memberof CreateDownloadRequest
     */
    dateFrom?: Date;

    /**
     * @type {Date}
     * @memberof CreateDownloadRequest
     */
    dateUntil?: Date;

    /**
     * @type {string}
     * @memberof CreateDownloadRequest
     */
    platform?: CreateDownloadRequestPlatformEnum;

    /**
     * @type {string}
     * @memberof CreateDownloadRequest
     */
    spread?: CreateDownloadRequestSpreadEnum;

    /**
     * @type {Array<string>}
     * @memberof CreateDownloadRequest
     */
    symbols?: Array<string>;
}

/**
 * @export
 * @enum {string}
 */
export enum CreateDownloadRequestPlatformEnum {
    MT4 = 'MT4',
    MT5 = 'MT5'
}
/**
 * @export
 * @enum {string}
 */
export enum CreateDownloadRequestSpreadEnum {
    MAX = 'SPREAD_MAX',
    OPEN = 'SPREAD_OPEN',
    MEAN = 'SPREAD_MEAN'
}

